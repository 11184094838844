/* global tw */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled('div')`
  ${tw('relative sm:mt-4 ml-8')};
  &:before {
    ${tw('absolute top-0 h-full bg-gray-800')};
    width: 3px;
    content: '';
  }
`

const Timeline = (props) => {
  const { children } = props

  return <Container>{children}</Container>
}

Timeline.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Timeline
