/* global tw */
import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled('div')`
  ${tw('md:flex overflow-hidden rounded shadow-lg')};
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
`

const FluidImage = styled(Img)`
  ${tw('w-full md:w-1/3 self-center')};
`

const Content = styled('div')`
  ${tw('w-full md:w-2/3 py-2 px-4 md:pb-0')};
`

const Card = (props) => {
  const { image, direction, children } = props

  return (
    <Container reverse={direction != null}>
      <FluidImage fluid={image.childImageSharp.fluid} />
      <Content>{children}</Content>
    </Container>
  )
}

Card.propTypes = {
  image: PropTypes.any.isRequired,
  direction: PropTypes.any,
  children: PropTypes.array.isRequired,
}

Card.defaultProps = {
  direction: null,
}

export default Card
