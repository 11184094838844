/* eslint-disable react/no-array-index-key */
/* global tw */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import Header from '../components/header'
import Link from '../components/link'
import Card from '../components/card'
import Timeline from '../components/timeline'
import TimelineItem from '../components/timeline-item'
import Certifications from '../components/certifications'

const Container = styled('div')`
  ${tw('flex flex-wrap')};
  sup {
    ${tw('text-xs')};
    vertical-align: super;
    line-height: 0;
  }
`

const LeftColumn = styled('div')`
  ${tw('max-w-3xl w-full xl:w-1/2 mb-4 ml-auto')};
`

const RightColumn = styled('div')`
  ${tw('max-w-3xl w-full xl:w-1/2 mb-4 mr-auto')};
`

const Quote = styled('blockquote')`
  ${tw('pl-2')};
  border-left: 5px solid #2d3748;
`

const HR = styled('hr')`
  ${tw('border-0')};
  width: 75%;
  height: 1px;
  background-image: linear-gradient(to right, rgba(61, 72, 82, 0), rgba(61, 72, 82, 0.75), rgba(61, 72, 82, 0));
`

const Footnote = styled('p')`
  ${tw('text-xs text-gray-700')};
`

const Box = styled('div')`
  ${tw('pt-2 pb-4 m-4 text-center')};
`

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      {
        allTimelineJson {
          edges {
            node {
              icon
              year
              entries
            }
          }
        }
        cover: file(relativePath: { eq: "index/cover.jpg" }) {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        microsoft: file(relativePath: { eq: "index/microsoft.jpg" }) {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        emerson: file(relativePath: { eq: "index/emerson.jpg" }) {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        provoke: file(relativePath: { eq: "index/provoke.jpg" }) {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header image={data.cover}>Shelly Bay Road, Maupuia, Wellington 6022, New Zealand</Header>
        <Container>
          <LeftColumn>
            <h1>The journey so far...</h1>
            <p>
              From an early age I&#39;ve always been interested in computers; always wondering how they worked, why they
              worked, and what else they could accomplish. This interest never left me, only growing more profound with
              every new discovery. From interacting with an AI application, to discovering the vastness of the internet,
              computers have left me fascinated with just how much power and mystery they hold.
            </p>
            <p>
              This curiosity led me to study computer science; reading countless numbers of books, blogs, and forums
              gave me an insight into the intricacies of software development.
            </p>
            <p>
              To help further my studies, I joined the{' '}
              <Link url="https://www.facebook.com/juniorpcs">Junior Philippine Computer Society</Link> shortly after
              enrolling at FEU - EAC (now <Link url="https://www.feutech.edu.ph">FEU Institute of Technology</Link>
              ). During my tenure, I was given the important task of collaborating with Microsoft to launch DreamSpark
              (now <Link url="https://imagine.microsoft.com/en-us">Imagine Cup</Link>) in the Philippines. This project
              meant a lot as successfully delivering on it would enable me and my fellow students, access to Microsoft
              professional design and development tools for free. At its completion, we launched JPCS Live, officially
              enabling qualified students from more than 20 schools in the Philippines, access to MSDN-AA &mdash;the
              academic version of MSDN.
            </p>
            <Card image={data.microsoft}>
              DreamSpark discussions at Microsoft.
              <h5>Makati, Philippines - 2009</h5>
            </Card>
            <p>
              Since then, I&#39;ve been developing software on and off when inspiration, or more often than not, the
              itch to learn something new strikes. In fact, this website started out of that; a showcase of my{' '}
              <del>lack of</del> photography skills, driving the need for me to generate content, thereby incentivizing
              and pushing me to go outside and take part in the world around me, to be consumed by new experiences I
              never thought I&#39;d do. From gaining altitude through mountaineering, to loosing it through S.C.U.B.A.
              diving, gone are the alibis of the old me, saying there's nothing out there, or that it's too dangerous.
            </p>
            <Quote>
              Leading and working closely with experienced software developers, on a variety of challenging projects,
              for better or for worse, keeps me up at night.
            </Quote>
            <p>
              My professional career started at <Link url="https://www.emerson.com/en-us">Emerson</Link>. I worked on a
              number of projects such as automation, data collection, web, and software maintenance lifecycle. I was
              also heavily involved in internationalization and branding efforts of our product offerings. Emerson was
              where I nurtured my talents as a software developer. There, I was encouraged to explore, to discover, to
              fail and to try again. My tenure there shaped who I am today, and where I would most likely be several
              years from now.
            </p>
            <Card image={data.emerson} direction="reverse">
              Recognition for the innovative ideas I&#39;ve contributed.
              <h5>Pasig, Philippines - 2016</h5>
            </Card>
            <p>
              After a couple of years, I&#39;ve decided to move on and look for new opportunities; I've had New Zealand
              on my sights for a long time, and by stroke of luck, I got a visa through the working holiday scheme.
            </p>
            <HR />
            <p>
              I joined <Link url="https://www.provokesolutions.com">Provoke</Link> shortly after arriving. There, my
              first project was to develop a proof of concept that enabled identification of objects through machine
              learning. It was a three week assignment that eventually ended up with myself and a few colleagues
              presenting at the New Zealand Defense Force convention later that year.
            </p>
            <Card image={data.provoke}>
              Computer Vision team, ready to present at a NZDF convention.
              <h5>Wellington, New Zealand - 2018</h5>
            </Card>
            <p>
              Over the coming months, I was given the opportunity to work on, and successfully deliver on a number of
              projects for companies such as <Link url="https://fireandemergency.nz">FENZ</Link>
              <sup>1</sup>, <Link url="https://www.anz.co.nz/personal">ANZ</Link>
              <sup>1</sup>, <Link url="https://www.nzpost.co.nz">NZ Post</Link>
              <sup>1</sup>, <Link url="https://www.marram.co.nz">Marram</Link>,{' '}
              <Link url="https://www.hopsta.co.nz">Hopsta</Link>,{' '}
              <Link url="https://justiceofthepeace.org.nz">Justice of the Peace</Link>,{' '}
              <Link url="https://www.microsoft.com">Microsoft</Link>
              <sup>1</sup>, <Link url="https://www.adhb.health.nz">Auckland District Health Board</Link>, and the{' '}
              <Link url="https://www.mpi.govt.nz">Ministry for Primary Industries</Link>. I was also involved in several
              Provoke projects, one of which is the current <Link url="https://www.provokesolutions.com">Provoke</Link>{' '}
              website.
            </p>
            <Footnote>1. Internal client projects.</Footnote>
          </LeftColumn>
          <RightColumn>
            <Timeline>
              {data.allTimelineJson.edges.map(({ node }, timelineIndex) => (
                <TimelineItem key={timelineIndex} icon={node.icon} year={node.year}>
                  <ul>
                    {node.entries.map((entry, entryIndex) => (
                      <li key={entryIndex}>{entry}</li>
                    ))}
                  </ul>
                </TimelineItem>
              ))}
            </Timeline>
            <Box>
              <Certifications />
            </Box>
          </RightColumn>
        </Container>
      </>
    )}
  />
)

export default IndexPage
/* eslint-enable react/no-array-index-key */
