/* global tw */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import Link from './link'

const Container = styled('div')`
  ${tw('flex flex-wrap w-full')};
`

const Badge = styled('div')`
  ${tw('w-24 xl:w-32 mx-auto text-center')};
`

const FluidImage = styled(Img)`
  ${tw('w-full')};
`

const Plus = styled('h1')`
  ${tw('mx-2 my-auto')};
  &:after {
    content: '+';
  }
`

const Certifications = () => (
  <StaticQuery
    query={graphql`
      {
        az203: file(relativePath: { eq: "components/certifications/azure-developer-associate-600x600.png" }) {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        az400: file(relativePath: { eq: "components/certifications/CERT-Expert-DevOps-Engineer-600x600.png" }) {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <h1>Professional Certifications</h1>
        <Container>
          <Badge>
            <Link
              url="https://docs.microsoft.com/en-us/learn/certifications/exams/az-204"
              title="Developing Solutions for Microsoft Azure"
            >
              <FluidImage fluid={data.az203.childImageSharp.fluid} />
              AZ-204
            </Link>
          </Badge>
          <Plus />
          <Badge>
            <Link
              url="https://docs.microsoft.com/en-us/learn/certifications/exams/az-400"
              title="DevOps Engineer Expert"
            >
              <FluidImage fluid={data.az400.childImageSharp.fluid} />
              AZ-400
            </Link>
          </Badge>
        </Container>
      </>
    )}
  />
)

export default Certifications
