/* global tw */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Container = styled('div')`
  ${tw('relative')};
  &:after,
  &:before {
    ${tw('block')};
    clear: both;
    content: '';
  }
`

const Icon = styled('div')`
  ${tw('absolute top-0 bg-gray-800 text-white text-center overflow-hidden rounded-full')};
  margin-left: -23px;
  width: 50px;
  line-height: 50px;
`

const Content = styled('div')`
  ${tw('px-4 py-2')};
  width: 95%;
  float: right;
`

const Year = styled('div')`
  ${tw('ml-2 font-bold')};
  margin-top: 6px;
`

const TimelineItem = (props) => {
  const { icon, year, children } = props

  return (
    <Container>
      <Icon>
        <FontAwesomeIcon icon={icon} />
      </Icon>
      <Content>
        <Year>{year}</Year>
        {children}
      </Content>
    </Container>
  )
}

TimelineItem.propTypes = {
  icon: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
}

export default TimelineItem
