/* global tw */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Container = styled('header')`
  ${tw('relative')};
`

const FluidImage = styled(Img)`
  ${tw('w-full my-4 rounded shadow-lg')};
  height: max(30vh, 30vw);
`

const Attribution = styled('h6')`
  ${tw('absolute my-0 text-white')};
  right: 10px;
  bottom: 10px;
`

const Header = (props) => {
  const { image, children } = props

  return (
    <Container>
      <FluidImage fluid={image.childImageSharp.fluid} imgStyle={{ objectPosition: '50% 25%' }} />
      <Attribution>{children}</Attribution>
    </Container>
  )
}

Header.propTypes = {
  image: PropTypes.any.isRequired,
  children: PropTypes.any,
}

Header.defaultProps = {
  children: null,
}

export default Header
